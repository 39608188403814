import * as React from "react";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
// import { Disclosure } from "../components/disclosure";
import { Disclosure, Transition } from "@headlessui/react";
import { ExternalLinkIcon } from "@heroicons/react/solid";
//import { zonedTimeToUtc, utcToZonedTime, parseIso } from "date-fns-tz";

const IndexPage = () => {
  const nowISO = new Date().toISOString();
  const liveDateISO = new Date("2021-12-03T15:55:00.000Z").toISOString();
  let isSaleLive = liveDateISO <= nowISO;

  return (
    <Layout>
      <main class="md:pb-20 md:px-5 pb-16 px-3">
        <title>Leonhart's Pop Up Shop</title>

        <div class="xl:pt-10 pt-5">
          <div class="mx-auto my-auto">
            <div class="flex xl:flex-row flex-col items-center justify-center">
              <div class="max-w-2xl max-h-2xl mb-1 hero-container">
                <StaticImage
                  src="../images/leonhart.png"
                  alt="Leonhart's Pop Up Shop"
                  placeholder="none"
                  className=" hero-container"
                  layout="constrained"
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>

              {/* PURPLE BOX */}
              {isSaleLive && (
                <div class="purple-box flex-grow max-w-3xl mb-5 bg-l-teal bg-no-repeat bg-center text-white bg-contain bg-cover xl:w-auto w-full">
                  {/* CTAs */}
                  <div
                    id="tickets"
                    class="flex justify-center items-center flex-wrap font-bold text-center  uppercase
                  pt-10 sm:px-10 lg:pt-12 px-3"
                  >
                    <div className="sold-out text-lg lg:text-4xl sm:text-2xl">
                      SOLD OUT
                    </div>
                    <p class="italic md:text-sm text-xs w-full flex-grow opacity-100 font-normal mt-1">
                      TICKETS ARE NONTRANSFERABLE
                    </p>
                  </div>
                  <div class="xl:px-6 md:px-7 p-5 mt-5">
                    <p class="text-base lg:text-xl font-bold text-center mb-3">
                      Join Leonhart at his first-ever in-person pop-up event!
                    </p>
                    <ul class="w-11/12 mx-auto text-center space-y-1.5 xl:space-y-1 info-details text-sm xl:text-base">
                      <li>
                        Hosted by Leonhart and attended by some of your other
                        favorite creators
                      </li>
                      <li>Entry into an immersive, Pokémon-world experience</li>
                      <li>Watch the live stream pack openings IRL</li>
                      <li>And much more!</li>
                    </ul>
                  </div>

                  {/* Place */}
                  <div
                    class="mx-auto lg:w-1/2 sm:w-1/2 w-3/4 flex justify-center flex-col p-4 transform rounded mt-5 mb-10 bg-purple-800 opacity-90"
                    style={{ boxShadow: "inset 3px 3px 0 0 #262461" }}
                  >
                    <div class="flex xl:flex-row-reverse flex-col items-center justify-around ">
                      <div>
                        <StaticImage
                          src="../images/thefactory.svg"
                          alt="The Bomb Factory logo"
                          placeholder="tracedSVG"
                          class="mx-auto mb-2 filter brightness-100"
                          width="180"
                        />
                      </div>
                      <div class="max-auto text-sm text-center mt-1">
                        <p class="text-base text-center font-bold mb-1">
                          Location
                        </p>
                        2713 Canton St
                        <br />
                        Dallas, TX 75226
                        <div class="lg:text-base sm:text-sm text-sm mt-1">
                          All ages
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Socials */}
                  <div
                    class="flex justify-center mx-auto flex-wrap items-start 
                xl:py-0 py-4 px-1 sm:mb-10 mb-4 px-10"
                  >
                    <a
                      class="btn-social"
                      target="_blank"
                      href="https://www.youtube.com/c/Leonhart54"
                    >
                      <svg
                        aria-hidden="true"
                        role="img"
                        class="md:w-8 w-5"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <g fill="none">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M21.582,6.186c-0.23-0.86-0.908-1.538-1.768-1.768C18.254,4,12,4,12,4S5.746,4,4.186,4.418 c-0.86,0.23-1.538,0.908-1.768,1.768C2,7.746,2,12,2,12s0,4.254,0.418,5.814c0.23,0.86,0.908,1.538,1.768,1.768 C5.746,20,12,20,12,20s6.254,0,7.814-0.418c0.861-0.23,1.538-0.908,1.768-1.768C22,16.254,22,12,22,12S22,7.746,21.582,6.186z M10,14.598V9.402c0-0.385,0.417-0.625,0.75-0.433l4.5,2.598c0.333,0.192,0.333,0.674,0,0.866l-4.5,2.598 C10.417,15.224,10,14.983,10,14.598z"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    </a>
                    <a
                      class="btn-social"
                      target="_blank"
                      href="https://www.instagram.com/leonhart/"
                    >
                      <svg
                        aria-hidden="true"
                        role="img"
                        class="md:w-8 w-5"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <g fill="none">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    </a>
                    <a
                      class="btn-social"
                      target="_blank"
                      href="https://twitter.com/LeonhartYT"
                    >
                      <svg
                        aria-hidden="true"
                        role="img"
                        class="md:w-8 w-5"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 30 30"
                      >
                        <g fill="none">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    </a>
                    <a
                      class="btn-social"
                      target="_blank"
                      href="https://www.twitch.tv/leonhartplays"
                    >
                      <svg
                        aria-hidden="true"
                        role="img"
                        class="md:w-8 w-5"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <g fill="none">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M 5.3632812 2 L 2 6.6367188 L 2 20 L 7 20 L 7 23 L 10 23 L 13 20 L 17 20 L 22 15 L 22 2 L 5.3632812 2 z M 6 4 L 20 4 L 20 13 L 17 16 L 12 16 L 9 19 L 9 16 L 6 16 L 6 4 z M 11 7 L 11 12 L 13 12 L 13 7 L 11 7 z M 16 7 L 16 12 L 18 12 L 18 7 L 16 7 z"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    </a>
                    <a
                      class="btn-social"
                      target="_blank"
                      href="https://www.facebook.com/Leonhart54YT"
                    >
                      <svg
                        aria-hidden="true"
                        role="img"
                        class="md:w-8 w-5"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 30 30"
                      >
                        <g fill="none">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M15,3C8.373,3,3,8.373,3,15c0,6.016,4.432,10.984,10.206,11.852V18.18h-2.969v-3.154h2.969v-2.099c0-3.475,1.693-5,4.581-5 c1.383,0,2.115,0.103,2.461,0.149v2.753h-1.97c-1.226,0-1.654,1.163-1.654,2.473v1.724h3.593L19.73,18.18h-3.106v8.697 C22.481,26.083,27,21.075,27,15C27,8.373,21.627,3,15,3z"
                            fill="currentColor"
                          />
                        </g>
                      </svg>
                    </a>
                  </div>
                </div>
              )}
              {/* END PURPLE BOX */}
            </div>
          </div>
        </div>

        {/* VIP */}
        <div class="mx-auto max-w-3xl details mb-14 xl:mt-14 mt-4 p-2">
          {isSaleLive && (
            <div id="vip">
              <Disclosure className="mb-4">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="btn-disclosure w-full flex items-center justify-between">
                      <div class="text-2xl text-left">Event F.A.Q.</div>
                      {/* Use the `open` render prop to rotate the icon when the panel is open */}
                      <svg
                        fill="#000000"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24px"
                        height="24px"
                        className={`${open ? "transform rotate-90" : ""}`}
                      >
                        <path fill="#161444" d="M9,19l9-7L9,5V19z" />
                      </svg>
                    </Disclosure.Button>
                    <Disclosure.Panel className="py-4 px-6 inset bg-l-teal-dark mb-4 rounded text-white">
                      <div className="list-disc">
                        <h3 className="text-2xl underline decoration-2">
                          <strong>Event FAQ:</strong>
                        </h3>
                        <br />
                        <p className="text-lg">
                          <strong>What is included with my ticket?</strong>
                        </p>
                        <p>
                          Please check your AXS account before arriving at the
                          event. Additionally, please be sure you have the AXS
                          Mobile app downloaded so you are able to scan in!
                        </p>
                        <br />
                        <div className="pl-4">
                          <p>
                            <strong>Pearl/VIP1 Package&nbsp;</strong>
                          </p>
                          <ul className="list-disc">
                            <li>One General Admission ticket</li>
                            <li>VIP early entry into the venue</li>
                            <li>Meet and greet opportunity with Leonhart</li>
                            <li>Participation in pack opening livestream</li>
                            <li>One reserved card pack</li>
                            <li>Official VIP laminate</li>
                            <li>Merchandise bundle</li>
                            <li>Exclusive VIP Lounge access</li>
                          </ul>
                          <br />
                          <p>
                            <strong>Sapphire/VIP2 Merch Bundle Package</strong>
                          </p>
                          <ul className="list-disc">
                            <li>One General Admission ticket</li>
                            <li>VIP early entry into the venue</li>
                            <li>Participation in pack opening livestream</li>
                            <li>One reserved card pack</li>
                            <li>Official VIP laminate</li>
                            <li>Merchandise bundle</li>
                          </ul>
                          <br />
                          <h3>
                            <strong>VIP3/GA + Pack</strong>
                          </h3>
                          <ul className="list-disc">
                            <li>One General Admission ticket</li>
                            <li>One reserved card pack</li>
                          </ul>
                          <br />
                          <p>
                            <strong>
                              General Admission - DOES NOT INCLUDE VINTAGE PACKS
                            </strong>
                          </p>
                          <ul className="list-disc">
                            <li>One General Admission ticket</li>
                          </ul>
                        </div>
                        <br />
                        <p className="text-lg">
                          <strong>Can you bring your own cards?&nbsp;</strong>
                        </p>
                        <p>
                          Yes, you can bring cards to trade and play versus
                          others!&nbsp;
                        </p>
                        <br />
                        <p className="text-lg">
                          <strong>
                            Will packs be available for purchase in the
                            store?&nbsp;
                          </strong>
                        </p>
                        <p>
                          While vintage packs are reserved for guests who
                          purchased VIP tickets in advance, there may be a
                          limited supply of newer packs available for purchase
                          from our sponsors and vendors.
                        </p>
                        <br />

                        <p className="text-lg">
                          <strong>
                            I can&rsquo;t make the show. Can I get my pack
                            mailed to me? Can I resell my ticket or transfer my
                            ticket to a friend?
                          </strong>
                        </p>
                        <p>
                          At this time, tickets are non-transferable,
                          non-refundable, and we are unable to accommodate
                          shipping any packs or refunding any purchases.
                        </p>
                        <br />

                        <p className="text-lg">
                          <strong>
                            Will there be food and drink at the event?
                          </strong>
                        </p>
                        <p>
                          Yes, we will have food and drink for sale during the
                          event.
                        </p>
                        <br />

                        <p className="text-lg">
                          <strong>
                            Can I bring a gift or something for Leonhart to
                            sign?
                          </strong>
                        </p>
                        <p>
                          You are welcome to bring a gift to leave with the
                          merchandise stand, but we{" "}
                          <strong>cannot guarantee </strong>you will be able to
                          personally hand it to him.&nbsp;
                        </p>
                        <br />
                        <br />
                        {/* GA FAQ */}

                        <h3 className="text-2xl underline decoration-2">
                          <strong>GA TICKET FAQ:</strong>
                        </h3>
                        <br />
                        <p className="text-lg">
                          <strong>
                            What is there to do if I bought a GA ticket without
                            packs?
                          </strong>
                        </p>
                        <p>
                          The event is designed to bring you into an immersive
                          Pokemon experience. In addition to photo
                          opportunities, guests are invited to make stops such
                          as:&nbsp;
                        </p>
                        <ul className="list-disc">
                          <li>
                            Professor Oak&rsquo;s Lab to shop exclusive
                            merchandise!
                          </li>
                          <li>
                            The Celadon City Department Store, where a variety
                            of our sponsors will host games and prizes!
                          </li>
                          <li>
                            The Pewter Museum of Science, where guests can view
                            some pieces of Leonhart&rsquo;s Pokemon collection.
                          </li>
                          <li>
                            The PSA Play and Trade station, with tables set up
                            for you to show off your skills with your favorite
                            deck, or see what you can trade for!
                          </li>
                          <li>
                            The Elgato Stadium will also be open for all guests
                            to watch vintage card packs be opened live.
                          </li>
                        </ul>
                        <br />
                        <p>
                          There will also be appearances by special guests like
                          Veronica Taylor, the original voice of Ash Ketchum.
                          Watch Leonhart&rsquo;s video below for an overview of
                          the event!&nbsp;
                        </p>
                        <br />

                        <div style={{ width: "90%", margin: "0 auto 0 auto" }}>
                          <div class="video-player-container">
                            <iframe
                              class="video-player"
                              src="https://www.youtube.com/embed/EtAdw1L_02w?controls=1&modestbranding=0&showinfo=0&rel=0"
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                            ></iframe>
                          </div>
                        </div>
                        <br />

                        <p className="text-lg">
                          <strong>
                            I have a GA ticket with packs. Can I open my card
                            live on stream?&nbsp;
                          </strong>
                        </p>
                        <p>
                          At this time, only guests who purchased VIP 1 and VIP
                          2 tickets are eligible to open their packs on
                          stream.&nbsp;
                        </p>
                        <br />
                        <p className="text-lg">
                          <strong>
                            I have a GA ticket with packs. Where do I pick up my
                            packs?&nbsp;
                          </strong>
                        </p>
                        <p>
                          You will pick up your pack at the merchandise store,
                          also called{" "}
                          <strong>Professor Oak&rsquo;s Lab.</strong> Please
                          note, you must open your pack before you leave the
                          store.
                        </p>
                        <br />
                        <p className="text-lg">
                          <strong>
                            VIP 3 GA + Pack: Where do I get my pack?&nbsp;
                          </strong>
                        </p>
                        <p>
                          You will pick up your pack in the general merchandise
                          store at the allotted time mentioned in your
                          email.&nbsp;
                        </p>
                        <br />
                        <p>
                          <strong>
                            VIP 3 GA + Pack and General Admission (Without
                            Pack): Venue Entry Time and Location&nbsp;
                          </strong>
                        </p>
                        <StaticImage
                          src="../images/ga-map.png"
                          alt="Leonhart's Pop Up Shop"
                          placeholder="none"
                          className=" hero-container rounded"
                          layout="constrained"
                          imgStyle={{ objectFit: "contain" }}
                        />
                        <br />
                        <br />
                        <br />
                        {/* VIP FAQ */}
                        <h3 className="text-2xl underline decoration-2">
                          <strong>VIP TICKET FAQ:</strong>
                        </h3>
                        <br />
                        <p className="text-lg">
                          <strong>
                            VIP 1 &amp; 2: Can I open my pack without going on
                            the livestream?
                          </strong>
                        </p>
                        <p>
                          Yes, absolutely! We require that you open your pack
                          prior to leaving the merchandise store store if you
                          are not streaming. You are not allowed to leave the
                          event with a fully sealed pack.
                        </p>
                        <br />
                        <p className="text-lg">
                          <strong>
                            VIP 1 &amp; 2: How do you mark my pack to ensure
                            that I will get the same one I picked in the store?
                          </strong>
                        </p>
                        <p>
                          You have been assigned to a smaller group within each
                          VIP tier. We are going to put a sticker on the bottom
                          of your VIP laminate that will correspond with the
                          sticker on your card pack. You will show your laminate
                          at the livestream to pick up your pack.
                        </p>
                        <br />
                        <p className="text-lg">
                          <strong>
                            VIP 1, 2, &amp; 3: Where do I get my pack?
                          </strong>
                        </p>
                        <p>
                          <strong>For VIP 1 + VIP 2:</strong> You will receive
                          your pack in the VIP exclusive check in booth. Further
                          instructions will be given upon entry into the venue
                          for where to go.
                          <br />
                          <strong>For VIP3 / GA + a pack:</strong> You will pick
                          up your pack in the general merchandise store at the
                          allotted time mentioned in your email. You will need
                          to stop by the Leonhart Information booth inside the
                          event to pick up your wristband.
                        </p>
                        <br />
                        <p>
                          <strong>
                            VIP 1 &amp; 2: I missed my stream time slot! Can I
                            hop in on another?
                          </strong>
                        </p>
                        <p>
                          Guests who miss their assigned streaming slot will be
                          added on to the tail end of the following stream’s
                          line.
                        </p>
                        <br />
                        <p>
                          <strong>
                            VIP 1 &amp; 2: Where do I go to meet Leonhart?
                          </strong>
                        </p>
                        <p>
                          Leonhart will be in our exclusive Mt Moon VIP lounge
                          several times throughout the day. You will have an
                          opportunity to meet him there at 10AM, 11:30AM, 1PM,
                          and 2:30PM.
                        </p>
                        <br />

                        <p>
                          <strong>
                            VIP 1 &amp; 2: Venue Entry Time and Location
                          </strong>
                        </p>
                        <StaticImage
                          src="../images/vip-map.png"
                          alt="Leonhart's Pop Up Shop"
                          placeholder="none"
                          className=" rounded"
                          layout="constrained"
                          imgStyle={{ objectFit: "contain" }}
                        />
                      </div>
                      <br />

                      <p>
                        <strong>
                          VIP 1, 2, &amp; 3: How early can I line up for my card
                          pick up?
                        </strong>
                      </p>
                      <p>
                        We ask that guests do not line up more than{" "}
                        <strong>15 minutes</strong>
                        prior to their card pick up time. Any lines formed will
                        be asked to return at 15 minutes prior to their pick up
                        time.
                      </p>
                      <br />

                      <p>
                        <strong>
                          VIP 1, 2, &amp; 3: Why was I put into a different
                          group from my friends, even though we bought the same
                          tier of VIP?
                        </strong>
                      </p>
                      <p>
                        To help guests avoid waiting in different lines all day,
                        and to allow fair allocation of the vintage card packs,
                        we have put guests into different VIP groups to maintain
                        flow of the event. At this time, we are unable to
                        accomodate moving any VIP guests to another group.
                      </p>
                      <br />

                      <p>
                        <strong>
                          VIP 1, 2, &amp; 3: My friends and I were sorted into
                          different groups. Can we be put into the same one?
                        </strong>
                      </p>
                      <p>
                        At this time, we are only able to keep groups who
                        purchased their tickets at the same time, under the same
                        account, by the same person, together.
                      </p>
                      <br />

                      <p>
                        <strong>
                          VIP 1, 2, &amp; 3: Will the first people picking up
                          their cards have the best selection?
                        </strong>
                      </p>
                      <p>
                        We are equally allocating each set of packs to each tier
                        of VIP ticketholders. Each group of guests will have
                        equal opportunities to pick each card packs.
                      </p>
                      <br />

                      <p>
                        <strong>
                          VIP 1, 2, &amp; 3: I am a parent who purchased a VIP
                          ticket for my child. Can I escort my child through the
                          event?
                        </strong>
                      </p>
                      <p>
                        Children under the age of 12 will be permitted one
                        escort through the event. The escort must be the
                        ticketholder who purchased the tickets.{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                        >
                          The escort will not receive a pack, merchandise, or
                          any VIP opportunities unless they have a VIP ticket.
                        </span>{" "}
                        Please email{" "}
                        <a href="mailto:vip@realgoodtouring.com">
                          vip@realgoodtouring.com
                        </a>{" "}
                        if you have any additional questions regarding this.
                      </p>
                      <br />

                      <p>
                        <strong>
                          VIP 1, 2, &amp; 3: How many card packs am I guaranteed
                          with my ticket purchase?
                        </strong>
                      </p>
                      <p>
                        You are guaranteed one card pack with each ticket
                        purchased.
                      </p>
                      <br />

                      <p>
                        <strong>
                          VIP 1, 2, &amp; 3: My friend can’t make the event. Can
                          I pick up their card pack?
                        </strong>
                      </p>
                      <p>
                        If you purchased their ticket (ie, you purchased four
                        tickets for you and your friends, and one is not coming)
                        you will be able to pick up their pack as long as it is
                        under your name.{" "}
                        <strong>
                          You must open the pack prior to leaving the event.
                        </strong>{" "}
                        <span
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          If the ticket is not associated with your name (in
                          other words, you did not personally purchase it), you
                          are unable to pick up their pack.
                        </span>
                      </p>
                      <br />

                      <p>
                        <strong>
                          VIP 1, 2, &amp; 3: I don't know when my card pick up
                          time or stream (for VIP1 + VIP2) starts and ends. Is
                          the schedule online?
                        </strong>
                      </p>
                      <p>
                        Even better- we're including your individual schedule on
                        the back of your laminate! Please refer there for
                        questions regarding what times you are to go to each
                        location for your experiences.
                      </p>
                      <br />

                      <p>
                        <strong>
                          VIP 1, 2, &amp; 3: I missed my assigned pack pick up
                          time. When can I pick up my pack?
                        </strong>
                      </p>
                      <p>
                        You are welcome to pick up your pack at any time after
                        your assigned group has already gone through.{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                        >
                          Missing your assigned pick up time will not, under any
                          circumstances, allow you to skip the card pick up
                          line.
                        </span>
                      </p>
                      <br />

                      <p>
                        <strong>
                          VIP 1, 2, &amp; 3: What does VIP Check in mean? When
                          will I get my merchandise bundle?
                        </strong>
                      </p>
                      <p>
                        VIP check in is when you will be invited to come and
                        pick up your card pack and merchandise bundle in the VIP
                        lounge.
                      </p>
                      <br />
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="btn-disclosure w-full flex items-center justify-between justify-items-start content-between">
                      <div class="text-2xl text-left">
                        Venue Covid Prevention Policy
                      </div>
                      <svg
                        fill="#000000"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24px"
                        style={{ minWidth: "24px" }}
                        height="24px"
                        className={`${open ? "transform rotate-90" : ""}`}
                      >
                        <path fill="#161444" d="M9,19l9-7L9,5V19z" />
                      </svg>
                    </Disclosure.Button>
                    <>
                      <Disclosure.Panel className="py-4 px-6 inset bg-l-teal-dark mb-4 rounded text-white">
                        <p>
                          We will require all attendees to wear a mask, and to
                          provide proof of a negative COVID-19 test result
                          within 72 hours of the event or proof of vaccination
                          to attend Leonhart's 90's Pop Up Event at The Factory
                          in Deep Ellum.
                        </p>
                        <br />
                        <p>
                          We will accept physical vaccination cards, digital
                          proof of vaccination, or photocopies of official
                          vaccination records. Approved tests include molecular,
                          PCR, or antigen results administered by an official
                          testing center. Home testing kit results will not be
                          accepted for entry.
                        </p>
                      </Disclosure.Panel>
                    </>
                  </>
                )}
              </Disclosure>
            </div>
          )}

          <div className="text-center">
            <a
              className={`btn-cta3 mx-auto inline-block sm:text-2xl text-xl font-bold align-middle ${
                isSaleLive ? "mt-12" : "mt-2"
              }`}
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSf0PbM28EStoPyjKjgUVTA9YUmM-9sxDK5xZtjU2d5Qo73gOQ/viewform"
            >
              Sign up for Leonhart's Pop-Up Newsletter
              <ExternalLinkIcon className="sm:h-6 sm:w-6 w-5 h-5 inline-block sm:ml-2 ml-1 mb-1" />
            </a>
          </div>
        </div>

        <div class="flex justify-center my-20">
          <img src="images/squirtle.gif" alt="Suirtle" class="m-2" />
          <img src="images/charizard.gif" alt="Charizard" class="m-2" />
          <img src="images/bulb.gif" alt="Bulbasaur" class="m-2" />
        </div>

        <div>
          <a href="https://www.realgoodtouring.com" target="_blank" id="rgt">
            <img className="mx-auto" src="images/rgt.png" width="150" />
          </a>
        </div>
      </main>
    </Layout>
  );
};

export default IndexPage;
